// include variables
@import "partials/variables";
// include functions
@import "partials/functions";
// include mixin
@import "partials/mixin";
.page-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .page-404-inner {
    min-height: 500px;
    background: url(../images/404-bg.jpg) center center no-repeat;
    width: 100%;
    position: relative;
    padding: 110px 0 45px;
    .logo {
      position: absolute;
      top: -30px;
      left: 50%;
      margin-left: -69px;

    }
    .page-404-info {
      max-width: 455px;
      margin: 0 auto;
      text-align: center;
      font-size: 14px;
      line-height: 21px;
      color: #333333;
      padding: 0 15px;
      a {
        color: #40659c;
      }
      img {
        display: block;
        max-width: 100%;
        margin: 0 0 40px;
      }
    }
  }
}